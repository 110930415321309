/*
 * @Author: zhangxin 1099962541@qq.com
 * @Date: 2023-02-27 14:15:34
 * @LastEditors: zhangxin 1099962541@qq.com
 * @LastEditTime: 2023-12-18 16:55:57
 * @FilePath: \devops-workspace-web-1.0\src\models\implements\userService\userCreator\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { IUserCreator } from "../../../interfaces/userService";
import { UserServiceApi } from "@/models/apis/UserServiceApi";
import { DataStore } from "@/models/utils/dataStore";
export class UserCreator implements IUserCreator {
  constructor(api: UserServiceApi) {
    this.userServiceApi = api;
  }
  private userServiceApi: UserServiceApi;
  /** 注册用户所需字段 */
  registerUserData = {
    account: "",
    cellphone: "",
    password: "",
    passwordConfirm: "",
    email: "",
    captcha: "",
    vertifyCode: '',
    termsConfirm: [],
    invite_code: '',
  };
  /** 清空注册字段 */
  clearRegisterUserData() {
    this.registerUserData = {
      account: "",
      cellphone: "",
      password: "",
      passwordConfirm: "",
      email: "",
      captcha: "",
      vertifyCode: '',
      termsConfirm: [],
      invite_code: '',
    };
  }

  //获取手机验证码
  sendVerificationCode(): Promise<{ status: boolean; msg: string; data?: any }> {
    return new Promise((re, rj) => {
      const data = {
        cellphone: this.registerUserData.cellphone,
        send_type: "register" as const,
        original_ip: DataStore.GetSessionStorage('clientIp')
      }
      this.userServiceApi.sentSms('placeholder', data).then(res => {
        if (res.data.status) {
          re({
            status: true,
            msg: '请求成功',
            data: res
          })
        } else {
          if (res.data.code === '412001') {
            re({
              status: false,
              msg: '请输入正确的手机号',
            })
          } else if (res.data.code === '412002') {
            re({
              status: false,
              msg: '1分钟后再试',
            })
          }
          else if (res.data.code === '410002') {
            re({
              status: false,
              msg: '该号码已被注册',
            })
          }
          else {
            re({
              status: false,
              msg: '短信服务发生异常,请稍后再试',
            })
          }

        }
      }).catch(e => {
        re({
          status: false,
          msg: '请求失败',
        })
      })
    })
  }

  /** 注册用户 */
  registerUser(register_typea: 'cellphone' | 'email' | null): Promise<{ status: boolean; msg: string; data?: any }> {
    return new Promise((re, rj) => {
      const captcha = register_typea === 'cellphone' ? this.registerUserData.vertifyCode : this.registerUserData.captcha
      const query = {
        captcha: captcha,
      };
      const data = {
        cellphone: this.registerUserData.cellphone,
        password: this.registerUserData.password,
        email: this.registerUserData.email,
        user_name: this.registerUserData.account,
        account: this.registerUserData.account,
        register_type: register_typea,
        invite_code: this.registerUserData.invite_code
      };
      this.userServiceApi.register(query, data as any).then((res) => {
        if (res.data.status) {
          re({
            status: true,
            msg: "注册成功",
            data: res
          });
        } else if (res.data.code == "410002") {
          re({
            status: false,
            msg: "该账号已被注册！",
          });
        } else if (res.data.code == "410001") {
          re({
            status: false,
            msg: "账号名格式不正确！",
          });
        } else if (res.data.code == "410012") {
          re({
            status: false,
            msg: "邮箱已被注册！",
          });
        } else if (res.data.code == "410013") {
          re({
            status: false,
            msg: "邮箱验证码错误！",
          });
        } else if (res.data.code == "410014") {
          re({
            status: false,
            msg: "邮箱验证码错误！",
          });
        } else if (res.data.code == "410018") {
          re({
            status: false,
            msg: "邀请码不合法！",
          });
        }
      });
    });
  }
  /** 注册时用户id实时验证 */
  validateUserId(): Promise<{ status: boolean; msg: string; data?: any }> {
    return new Promise((re, rj) => {
      const data = {
        account: this.registerUserData.account,
      };
      this.userServiceApi.verifyUserId(data).then((res) => {
        if (res.data.status) {
          re({
            status: true,
            msg: "用户账号可用",
          });
        } else if (res.data.code == "410001") {
          re({
            status: false,
            msg: "账号名必须为6-24位小写字母或数字！",
          });
        } else if (res.data.code == "410005") {
          re({
            status: false,
            msg: "账号名必须为6-24位小写字母或数字！",
          });
        } else if (res.data.code == "410002") {
          re({
            status: false,
            msg: "该账号已重复注册！",
          });
        }
      });
    });
  }
}
