import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css';
import './styles/element/index.scss'
import { qianKunStart } from "./qiankun/index.js";

createApp(App).use(router).use(ElementPlus).mount("#app");

qianKunStart();

