import { IUserLogger } from "../../../interfaces/userService";
import { UserServiceApi } from "@/models/apis/UserServiceApi";
import { UserService } from "..";

import { DataStore } from "@/models/utils/dataStore";
export class UserLogger implements IUserLogger {
  constructor(api: UserServiceApi) {
    this.userServiceApi = api;
  }
  private userServiceApi: UserServiceApi;
  /** 登录用户所需信息 */
  public UserInfo = {
    login_type: "0" as "0" | "2",
    username: "",
    password: "",
    identify_code: "",
    email: "",
    captcha: '',
    cellphone: ''
  };
  /** 登录用户 */
  public loginUser(
    rememberMe = false
  ): Promise<{ status: boolean; msg: string; data?: any }> {
    DataStore.DeleteCookie("user-token");
    return new Promise((re, rj) => {
      const data = {
        user: this.UserInfo.login_type === '0' ? this.UserInfo.username : '',
        password: this.UserInfo.login_type === '0' ? this.UserInfo.password : '',
        login_type: this.UserInfo.login_type,
        email: this.UserInfo.email,
        captcha: this.UserInfo.captcha,
        cellphone: this.UserInfo.login_type === '2' ? this.UserInfo.cellphone : ''

      };
      this.userServiceApi.login(data).then((res) => {
        if (res.data.status) {
          DataStore.SetCookie("user-token", res.data.res!.token!);
          if (rememberMe) {
            DataStore.SetCookie("cc-loginType", this.UserInfo.login_type);
            DataStore.SetCookie("cc-username", this.UserInfo.username);
            DataStore.SetCookie("cc-password", this.UserInfo.password);
            DataStore.SetCookie("cc-email", this.UserInfo.email);
          }
          re({
            status: true,
            msg: "登录成功",
          });
        } else if (res.data.code === '410004') {
          re({
            status: false,
            msg: "账号名或密码不正确！",
          });
        } else if (res.data.code === '410013') {
          re({
            status: false,
            msg: "验证码错误",
          });
        } else if (res.data.code === '410014') {
          re({
            status: false,
            msg: "验证码已过期",
          });
        } else {
          re({
            status: false,
            msg: "登录异常，请联系管理员",
          });
        }
      });
    });
  }

  //获取手机验证码
  sendVerificationCode(): Promise<{ status: boolean; msg: string; data?: any }> {
    return new Promise((re, rj) => {
      const data = {
        cellphone: this.UserInfo.cellphone,
        send_type: "login" as const,
        original_ip: DataStore.GetSessionStorage('clientIp')
      }
      this.userServiceApi.sentSms('placeholder', data).then(res => {
        if (res.data.status) {
          re({
            status: true,
            msg: '请求成功',
            data: res
          })
        } else {
          if (res.data.code === '412001') {
            re({
              status: false,
              msg: '请输入正确的手机号',
            })
          } else if (res.data.code === '412002') {
            re({
              status: false,
              msg: '1分钟后再试',
            })
          }
          else if (res.data.code === '410002') {
            re({
              status: false,
              msg: '该号码已被注册',
            })
          }
          else {
            re({
              status: false,
              msg: '短信服务发生异常,请稍后再试',
            })
          }

        }
      }).catch(e => {
        re({
          status: false,
          msg: '请求失败',
        })
      })
    })
  }
  /** onestore登录用户 */
  public loginUserByOnestore(
    authorization_code: string
  ): Promise<{ status: boolean; msg: string; data?: any }> {
    DataStore.DeleteCookie("user-token");
    return new Promise((re, rj) => {
      const data = {
        authorization_code: authorization_code,
      };
      this.userServiceApi.oneStoreLogin(data).then((res) => {
        if (res.data.status) {
          DataStore.SetCookie("user-token", res.data.res!.token!);
          re({
            status: true,
            msg: "登录成功",
          });
        } else {
          re({
            status: false,
            msg: "账号名或密码不正确！",
          });
        }
      });
    });
  }
  /** 登出用户 */
  public logoutUser(): Promise<{ status: boolean; msg: string; data?: any }> {
    return new Promise((re, rj) => {
      this.userServiceApi.logout().then((res) => {
        if (res.status) {
          this.UserInfo = {
            login_type: "0",
            username: "",
            password: "",
            identify_code: "",
            email: "",
            captcha: '',
            cellphone: ''
          };
          const userService = UserService.getInstance();
          userService.userChecker.initUserInfo();
          DataStore.ClearAll();
          re({
            status: true,
            msg: "已退出登录！",
          });
        } else {
          re({
            status: false,
            msg: "退出失败！",
          });
        }
      });
    });
  }
  /** 获取cookie存储的用户名和密码 */
  public getUserInfoFromCookie() {
    if (DataStore.GetCookie("cc-loginType") == "0") {
      this.UserInfo.login_type = DataStore.GetCookie("cc-loginType") as
        | "0"
        | "2";
      if (
        DataStore.GetCookie("cc-username") &&
        DataStore.GetCookie("cc-password")
      ) {
        this.UserInfo.username = DataStore.GetCookie("cc-username");
        this.UserInfo.password = DataStore.GetCookie("cc-password");
      }
    } else if (DataStore.GetCookie("cc-loginType") == "1") {
      this.UserInfo.login_type = DataStore.GetCookie("cc-loginType") as
        | "0"
        | "2";
      if (
        DataStore.GetCookie("cc-email") &&
        DataStore.GetCookie("cc-password")
      ) {
        this.UserInfo.email = DataStore.GetCookie("cc-email");
        this.UserInfo.password = DataStore.GetCookie("cc-password");
      }
    }
  }
}
