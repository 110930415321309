class DataStore {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private constructor() {
    }
    // LocalStorage
    static GetLocalStorage(key: string): string { return localStorage.getItem(key) || ""; }
    static SetLocalStorage(key: string, value: string) { localStorage.setItem(key, value); }

    // SessionStorage
    static GetSessionStorage(key: string): string { return sessionStorage.getItem(key) || ""; }
    static SetSessionStorage(key: string, value: string) { sessionStorage.setItem(key, value); }

    //Cookies
    // static GetCookie(key: string): string { const name = key + "="; const ca = document.cookie.split(";"); for (let index = 0; index < ca.length; index++) { const e = ca[index].trim(); if (e.indexOf(name) == 0) { return e.substring(name.length, e.length); } } return ""; }
    // static SetCookie(key: string, value: string, exdays?: number) {
    //     if (exdays) { 
    //         const d = new Date(); d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000)); 
    //         document.cookie = `${key}=${value};expires=${d.toUTCString()};path=/`; 
    //     } 
    //     else { 
    //         document.cookie = `${key}=${value}`; 
    //     } 
    // }
    // static DeleteCookie(key: string) { 
    //     document.cookie = key+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/"
    //  }
    //设置cookie
    static SetCookie(name: string, value: string, hours: number = 24) {
        var d = new Date();
        d.setTime(d.getTime() + hours * 3600 * 1000);
        document.cookie = name + '=' + value + '; expires=' + d.toUTCString()+';path=/';
    }

    //获取cookie
    static GetCookie(name: string) {
        var arr = document.cookie.split('; ');
        for (var i = 0; i < arr.length; i++) {
            var temp = arr[i].split('=');
            if (temp[0] == name) {
                return temp[1];
            }
        }
        return '';
    }

    //删除cookie
    static DeleteCookie(name: string) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;';
    }

    //清空
    static ClearAll() {
        //清localstorage
        this.SetLocalStorage("appCreateData", "")
        this.SetLocalStorage("currentAppId", "")
        this.SetLocalStorage("currentUserId", "")
        this.SetLocalStorage("currentWorkspaceId", "")
        this.SetLocalStorage("currentNfsInstanceId", "")
        //清sessionstorage

        //清cookies
        this.DeleteCookie("user-token")
    }
}

export { DataStore };
