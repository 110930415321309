import { defineStore } from "pinia";
import { store } from "@/store";
const globalStore = defineStore("globalStore", {
    persist: false,
    state: () => ({
        isScrollTo: false,
    }),
    getters: {
        getScrollTo(state) {
            return state.isScrollTo;
        },
    },
    actions: {
        setScrollTo(scroll: boolean) {
            this.isScrollTo = scroll
        },
    }
})

export function useGlobalStore() {
    return globalStore(store);
}