import { IUserChecker } from "../../../interfaces/userService";
import { DataTransfer } from "@/models/utils/dataTransfer";
import { UserService } from "..";
import { UserServiceApi } from "@/models/apis/UserServiceApi";
import { useUserStore } from "@/store/modules/user";
export class UserChecker implements IUserChecker {
    constructor(api: UserServiceApi) {
        this.userServiceApi = api
    }
    store = useUserStore();
    private userServiceApi: UserServiceApi;
    basicUserInfo = {
        user_id: '',
        account: '',
        user_name: '',
        user_icon: '',
        icon_name: '',
        cellphone: '',
        email: '',
        theme_color: '',
        user_status: 1,
        duration: 24 as 24 | 48 | null,
        login_time: '',
        github: {
            hasgithub: false,
            token: '',
            tokenId: ''
        },
        gitea: {
            hasgitea: false,
            token: ''
        },
        store_authorized: "false",
        workspace_authorized: "false",
        xiaotie_authorized: "false"
    }
    /** 清空用户信息 */
    initUserInfo() {
        this.basicUserInfo = {
            user_id: '',
            account: '',
            user_name: '',
            user_icon: '',
            icon_name: '',
            cellphone: '',
            user_status: 1,
            duration: 24 as 24 | 48 | null,
            login_time: '',
            email: '',
            theme_color: '',
            github: {
                hasgithub: false,
                token: '',
                tokenId: ''
            },
            gitea: {
                hasgitea: false,
                token: ''
            },
            store_authorized: "false",
            workspace_authorized: "false",
            xiaotie_authorized: "false"
        }
    }
    /** 通过userId查找当前用户信息 */
    checkUser(userId: string): Promise<{ status: boolean; msg: string; data?: any; }> {
        return new Promise((re, rj) => {
            this.userServiceApi.getUser(userId, { get_type: '0' }).then(res => {
                if (res.data.status) {
                    re({
                        status: true,
                        msg: '查询成功',
                        data: res.data.res
                    })
                }
                else {
                    return {
                        status: false,
                        msg: '查询失败！'
                    }
                }
            }
            )
        })
    }
    /** 全局模糊搜索*/
    searchUser(userId: string, query: string): Promise<{ status: boolean; msg: string; data?: any; }> {
        return new Promise((re, rj) => {
            this.userServiceApi.getUser(userId, { get_type: '0', keyword: query }).then(res => {
                if (res.data.status) {
                    re({
                        status: true,
                        msg: '查询成功',
                        data: res.data.res
                    })
                }
                else {
                    return {
                        status: false,
                        msg: '查询失败！'
                    }
                }
            }
            )
        })
    }
    /** 通过token查找当前用户信息 */
    checkCurrentUser(): Promise<{ status: boolean; msg: string; data?: any; }> {
        return new Promise((re, rj) => {
            this.userServiceApi.getUser('currentUser', { get_type: '1' }).then(res => {
                if (res.data.status) {
                    DataTransfer.MappingObject(res.data.res!, this.basicUserInfo)
                    UserService.setCurrentUserId(this.basicUserInfo.user_id)
                    this.store.setUserInfo(res.data.res);

                    if (this.basicUserInfo.duration == 48) {
                        this.basicUserInfo.login_time = "48小时"
                    } else if (this.basicUserInfo.duration == 24) {
                        this.basicUserInfo.login_time = "24小时"
                    }
                    // this.basicUserInfo = {
                    //     ...this.basicUserInfo,
                    //     user_icon: this.basicUserInfo.user_icon + "?t=" + new Date().getTime(),
                    // };
                    re({
                        status: true,
                        msg: '查询成功',
                    })
                }
                else {
                    re({
                        status: false,
                        msg: '查询失败！'
                    })
                }
            }
            )
        })
    }
    /** 查询用户github相关token */
    checkGithubToken(userId: string): Promise<{ status: boolean; msg: string; data?: boolean; }> {
        return new Promise((re, rj) => {
            this.userServiceApi.getGitToken(userId, { type: "github" }).then(res => {
                if (res.data.status) {
                    if (res.data.res.length != 0) {
                        this.basicUserInfo.github.hasgithub = true
                        this.basicUserInfo.github.token = res.data.res[0]!.token!
                        this.basicUserInfo.github.tokenId = res.data.res[0]!.git_token_id!
                    } else {
                        this.basicUserInfo.github.hasgithub = false
                        this.basicUserInfo.github.token = ''
                    }
                    re({
                        status: true,
                        msg: '查询成功',
                    })
                }
                else {
                    re({
                        status: false,
                        msg: '查询失败！'
                    })
                }
            }
            )
        })
    }
    /** 查询用户gitea相关token */
    checkGiteaToken(userId: string): Promise<{ status: boolean; msg: string; data?: boolean; }> {
        return new Promise((re, rj) => {
            this.userServiceApi.getGitToken(userId, { type: "gitea" }).then(res => {
                if (res.data.status) {
                    if (res.data.res.length != 0) {
                        this.basicUserInfo.gitea.hasgitea = true
                        this.basicUserInfo.gitea.token = res.data.res[0]!.token!
                    } else {
                        this.basicUserInfo.gitea.hasgitea = false
                    }
                    re({
                        status: true,
                        msg: '查询成功',
                    })
                }
                else {
                    re({
                        status: false,
                        msg: '查询失败！'
                    })
                }
            }
            )
        })
    }
    /** 授权相关信息 */
    enableAuthorization(userId: string, data: "workspace" | "store" | "xiaotie"): Promise<{ status: boolean; msg: string; data?: boolean; }> {
        return new Promise((re, rj) => {
            this.userServiceApi.enableAuthorization(userId, data).then(res => {
                if (res.data.status) {
                    re({
                        status: true,
                        msg: '查询成功',
                    })
                }
                else {
                    re({
                        status: false,
                        msg: '查询失败！'
                    })
                }
            }
            )
        })
    }
}
