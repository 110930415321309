import { defineStore } from "pinia";
import { store } from "@/store";
const userStore = defineStore("userStore", {
    persist: true,
    state: () => ({
        token: "",
        userInfo: {
            user_id: "",
            user_icon: "",
            user_name: '',
            user_email: '',
            user_cellphone: ''
        },
    }),
    getters: {
        getUserId(state) {
            return state.userInfo.user_id;
        },
        getUserName(state) {
            return state.userInfo.user_name;
        },
        getUserIcon(state) {
            return state.userInfo.user_icon;
        },
        getUserPhone(state) {
            return state.userInfo.user_cellphone;
        },
        getUserEmail(state) {
            return state.userInfo.user_email;
        },
        getUserToken(state) {
            return state.token;
        },
    },
    actions: {
        setToken(token: string) {
            this.token = token
        },
        setUserId(user_id: string) {
            this.userInfo.user_id = user_id
        },

        setUserInfo(user: any) {
            console.log(1111111111111, user)
            this.userInfo.user_id = user.user_id
            this.userInfo.user_icon = user.user_icon
            this.userInfo.user_name = user.user_name
            this.userInfo.user_email = user.email
            this.userInfo.user_cellphone = user.cellphone
        },
        cleanAll() {
            this.token = ''
            this.userInfo.user_id = ''
            this.userInfo.user_name = ''
            this.userInfo.user_email = ''
            this.userInfo.user_cellphone = ''
        },
    }
})

export function useUserStore() {
    return userStore(store);
}