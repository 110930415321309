import { IUserService } from "../../interfaces/userService";
import { UserLogger } from "./userLogger/index";
import { UserCreator } from "./userCreator/index";
import { UserEditor } from "./userEditor/index";
import { UserDeletor } from "./userDeletor/index";
import { UserChecker } from "./userChecker/index";
import { DataStore } from "../../utils/dataStore";
import { UserServiceApi } from "@/models/apis/UserServiceApi";
import { CloudServiceApi } from "@/models/apis/CloudServiceApi";

export class UserService implements IUserService {
  private userServiceApi: UserServiceApi = new UserServiceApi();
  private cloudServiceApi: CloudServiceApi = new CloudServiceApi();
  private static instance: UserService;
  private static currentUserId: string;
  public userLogger = new UserLogger(this.userServiceApi);
  public userCreator = new UserCreator(this.userServiceApi);
  public userChecker = new UserChecker(this.userServiceApi);
  public userEditor = new UserEditor(this.userServiceApi, this.cloudServiceApi);
  public userDeletor = new UserDeletor(this.userServiceApi);

  public static getInstance() {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }
  /** 获取当前userId，如果为空则尝试去store获取 */
  public static getCurrentUserId(): string {
    if (!this.currentUserId) {
      this.currentUserId = DataStore.GetLocalStorage("currentUserId");
    }
    return this.currentUserId;
  }
  /** 视图层登录用户时调用，并保存到store */
  public static setCurrentUserId(appId: string): void {
    this.currentUserId = appId;
    DataStore.SetLocalStorage("currentUserId", this.currentUserId as string);
  }
}
