import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import _ from "lodash";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login/index.vue"),
    meta: { permission: false, title: "登录", showNavBar: false },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/register/index.vue"),
    meta: { permission: false, title: "注册", showNavBar: false },
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/home/index.vue"),
    meta: { permission: false, title: "首页", showNavBar: true },
  },
  {
    path: "/computingSupport",
    name: "ComputingSupport",
    component: () => import("../views/computingSupport/index.vue"),
    meta: { permission: false, title: "后端计算支持", showNavBar: true },
  },
  {
    path: "/userFeedBack",
    name: "UserFeedBack",
    component: () => import("../views/userFeedBack/index.vue"),
    meta: { permission: false, title: "用户反馈", showNavBar: true },
  },
  {
    path: "/activitySquare",
    name: "ActivitySquare",
    component: () => import("../views/activitySquare/index.vue"),
    meta: { permission: false, title: "活动广场", showNavBar: true },
    redirect: "/activitySquare/noobRegion",
    children: [
      {
        path: "noobRegion",
        name: "NoobRegion",
        component: () =>
          import("../views/activitySquare/pages/noobRegion/index.vue"),
        meta: { permission: false, title: "新手任务专区", showNavBar: true },
      },
      {
        path: "limitedTime",
        name: "LimitedTime",
        component: () =>
          import("../views/activitySquare/pages/limitedTime/index.vue"),
        meta: { permission: false, title: "限时活动", showNavBar: true },
      },
    ],
  },
  {
    path: "/activityDetail",
    name: "ActivityDetail",
    component: () =>
      import("../views/activitySquare/pages/limitedTimeDetail/index.vue"),
    meta: { permission: false, title: "活动详情", showNavBar: true },
  },
  {
    path: "/document",
    name: "Document",
    component: () =>
      import("../views/documentCenter/index.vue"),
    meta: { permission: false, title: "文档中心", showNavBar: true },
  },
  {
    path: "/application",
    name: "Application",
    component: () =>
      import(
        "../views/activitySquare/pages/limitedTimeDetail/pages/register.vue"
      ),
    meta: { permission: false, title: "活动报名", showNavBar: true },
  },
  {
    path: "/applicationSuccess",
    name: "ApplicationSuccess",
    component: () =>
      import(
        "../views/activitySquare/pages/limitedTimeDetail/pages/registerSuccess.vue"
      ),
    meta: { permission: false, title: "活动报名", showNavBar: true },
  },
  {
    path: "/resultSubmit",
    name: "ResultSubmit",
    component: () =>
      import(
        "../views/activitySquare/pages/limitedTimeDetail/pages/resultSubmit.vue"
      ),
    meta: { permission: false, title: "成果提交", showNavBar: true },
  },
  {
    path: "/resultSuccsss",
    name: "ResultSuccsss",
    component: () =>
      import(
        "../views/activitySquare/pages/limitedTimeDetail/pages/submitSuccess.vue"
      ),
    meta: { permission: false, title: "成果提交", showNavBar: true },
  },
  {
    path: "/cloudAppPlatform",
    name: "cloudAppPlatform",
    component: () => import("../views/cloudAppPlatForm/index.vue"),
  },
  {
    path: "/aboutUs",
    name: "AboutUs",
    component: () => import("../views/aboutUs/index.vue"),
    meta: { permission: false, title: "成果提交", showNavBar: true },
  },
  {
    path: "/usercenter",
    name: "Usercenter",
    redirect: "/usercenter/basicinfo",
    component: () => import("@/views/usercenter/index.vue"),
    meta: {
      permission: true,
      title: "个人中心",
      showNavBar: true,
    },
    children: [
      {
        path: "/usercenter/basicinfo",
        name: "UsercenterBasicInfo",
        component: () => import("@/views/usercenter/pages/basicInfoBlock.vue"),
        meta: { permission: true, title: "基础信息", showNavBar: true },
      },
      {
        path: "/usercenter/securityinfo",
        name: "UsercenterSecurityInfo",
        component: () =>
          import("@/views/usercenter/pages/securityInfoBlock.vue"),
        meta: { permission: true, title: "安全信息", showNavBar: true },
      },
      {
        path: "/usercenter/servicemanager",
        name: "UserCenterServiceManager",
        component: () =>
          import("@/views/usercenter/pages/serviceManageBlock.vue"),
        meta: { permission: true, title: "服务管理", showNavBar: true },
      },
      // {
      //   path: "wallet",
      //   name: "Wallet",
      //   component: () => import("@/views/usercenter/pages/wallet/index.vue"),
      //   meta: { permission: true, title: "钱包", hasToolBox: false },
      // },
    ],
  },
];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
// 解决子应用和主应用路由版本不一致的问题
router.beforeEach((to, from, next) => {
  if (window.history.state === null) {
    history.replaceState(
      {
        back: from.path,
        current: to.path,
        forward: null,
        position: NaN,
        replaced: false,
        scroll: null,
      },
      window.location.origin + to.path
    );
  }
  next();
});

// export default router
