import{ initGlobalState, MicroAppStateActions }from'qiankun'

//初始化数据
const initialState = {
 
}
// 初始化
const actions = initGlobalState(initialState)

export default actions
