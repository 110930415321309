import { UserServiceApi } from "@/models/apis/UserServiceApi";
import { CloudServiceApi } from "@/models/apis/CloudServiceApi";
import { IUserEditor } from "../../../interfaces/userService";
import { DataStore } from "@/models/utils/dataStore";

export class UserEditor implements IUserEditor {
  constructor(api: UserServiceApi, api1: CloudServiceApi) {
    this.userServiceApi = api
    this.cloudServiceApi = api1
  }
  private userServiceApi: UserServiceApi;
  private cloudServiceApi: CloudServiceApi
  public userGithubTokenId = ''
  public codebase = {
    type: "GitHub" as "GitHub" | "Gitea",
    token: '',
    organization: ''
  }
  /** 待修改用户字段 */
  public editUserData = {
    user_id: '',
    account: '',
    user_name: '',
    user_icon: null as unknown as File,
    icon_name: '',
    new_password: '',
    old_password: '',
    cellphone: '',
    email: '',
    captcha: '',
    theme_color: '',
    duration: 24 as 24 | 48 | null,
    workspace_authorized: null as "true" | "false" | null,
    store_authorized: null as "true" | "false" | null,
    xiaotie_authorized: null as "true" | "false" | null
  }
  /** 更改账户名 */
  public editAccount(userId: string): Promise<{ status: boolean; msg: string; data?: any; }> {
    return new Promise((re, rj) => {
      const data = {
        user_id: '',
        account: this.editUserData.account,
        user_name: '',
        user_icon: null,
        icon_name: '',
        new_password: '',
        old_password: '',
        cellphone: '',
        email: '',
        captcha: '',
        theme_color: '',
        duration: null,
        workspace_authorized: null,
        store_authorized: null,
        xiaotie_authorized: null
      }
      this.userServiceApi.updateUser(userId, data).then(res => {
        if (res.data.status) {
          re({
            status: true,
            msg: '修改成功！'
          })
        }
        else if (res.data.code == '410003') {
          re({
            status: false,
            msg: '修改失败！'
          })
        }
      }
      )
    })
  }
  /** 更改相应用户名称 */
  public editUserName(userId: string): Promise<{ status: boolean; msg: string; data?: any; }> {
    return new Promise((re, rj) => {
      const data = {
        user_id: '',
        account: '',
        user_name: this.editUserData.user_name,
        user_icon: null,
        icon_name: '',
        new_password: '',
        old_password: '',
        cellphone: '',
        email: '',
        captcha: '',
        theme_color: '',
        duration: null,
        workspace_authorized: null,
        store_authorized: null,
        xiaotie_authorized: null
      }
      this.userServiceApi.updateUser(userId, data).then(res => {
        if (res.data.status) {
          re({
            status: true,
            msg: '修改成功！'
          })
        }
        else if (res.data.code == '410003') {
          re({
            status: false,
            msg: '修改失败！'
          })
        }
      }
      )
    })
  }
  /** 更改相应用户密码 */
  public editUserPassword(userId: string): Promise<{ status: boolean; msg: string; data?: any; }> {
    return new Promise((re, rj) => {
      const data = {
        user_id: '',
        account: '',
        user_name: '',
        user_icon: null,
        icon_name: '',
        new_password: this.editUserData.new_password,
        old_password: this.editUserData.old_password,
        cellphone: '',
        email: '',
        captcha: '',
        theme_color: '',
        duration: null,
        workspace_authorized: null,
        store_authorized: null,
        xiaotie_authorized: null
      }
      this.userServiceApi.updateUser(userId, data).then(res => {
        if (res.data.status) {
          re({
            status: true,
            msg: '修改成功！'
          })
        } else if (res.data.code == '410003') {
          re({
            status: false,
            msg: '原密码输入错误!'
          })
        }
      }
      )
    })
  }
  /** 更改相应用户邮箱*/
  public editUserEmail(userId: string): Promise<{ status: boolean; msg: string; data?: any; }> {
    return new Promise((re, rj) => {
      const data = {
        user_id: '',
        account: '',
        user_name: '',
        user_icon: null,
        icon_name: '',
        new_password: '',
        old_password: '',
        cellphone: '',
        email: this.editUserData.email,
        captcha: this.editUserData.captcha,
        theme_color: '',
        duration: null,
        workspace_authorized: null,
        store_authorized: null,
        xiaotie_authorized: null
      }
      this.userServiceApi.updateUser(userId, data).then(res => {
        if (res.data.status) {
          re({
            status: true,
            msg: '修改成功！'
          })
        } else if (res.data.code == '410013') {
          re({
            status: false,
            msg: '验证码错误！'
          })
        }
        else if (res.data.code == '410003') {
          re({
            status: false,
            msg: '修改失败！'
          })
        } else if (res.data.code == '410012') {
          re({
            status: false,
            msg: '该邮箱已被绑定！'
          })
        } else if (res.data.code == '410014') {
          re({
            status: false,
            msg: '验证码已过期！'
          })
        }
        //  else if (res.data.code == '999999') {
        //     re({
        //         status: false,
        //         msg: '验证码错误！'
        //     })
        // }
      }
      )
    })
  }
  /** 更改相应用户头像*/
  public editUserPic(): Promise<{ status: boolean; msg: string; data?: any; }> {
    return new Promise((re, rj) => {
      const data = {
        user_id: this.editUserData.user_id,
        account: '',
        user_name: '',
        user_icon: this.editUserData.user_icon,
        icon_name: this.editUserData.icon_name,
        new_password: '',
        old_password: '',
        cellphone: '',
        email: '',
        captcha: '',
        theme_color: '',
        duration: null,
        workspace_authorized: null,
        store_authorized: null,
        xiaotie_authorized: null
      }
      this.userServiceApi.updateUser(data.user_id, data).then(res => {
        if (res.data.status) {
          re({
            status: true,
            msg: '修改成功！'
          })
        } else if (res.data.code == '410003') {
          re({
            status: false,
            msg: '修改失败！'
          })
        }
      }
      )
    })
  }
  /** 更改用户token有效期*/
  public edittokenDuration(userId: string): Promise<{ status: boolean; msg: string; data?: any; }> {
    return new Promise((re, rj) => {
      const data = {
        user_id: this.editUserData.user_id,
        account: '',
        user_name: '',
        user_icon: this.editUserData.user_icon,
        icon_name: this.editUserData.icon_name,
        new_password: '',
        old_password: '',
        cellphone: '',
        email: '',
        captcha: '',
        theme_color: '',
        duration: this.editUserData.duration === 48 ? 48 : 24 as any,
        workspace_authorized: null,
        store_authorized: null,
        xiaotie_authorized: null
      }
      this.userServiceApi.updateUser(userId, data).then(res => {
        if (res.data.status) {
          re({
            status: true,
            msg: '修改成功！'
          })
        } else if (res.data.code == '410003') {
          re({
            status: false,
            msg: '修改失败！'
          })
        }
      }
      )
    })
  }
  //获取手机验证码
  public sendCellphoneCaptcha(user_id: string): Promise<{ status: boolean; msg: string; data?: any }> {
    return new Promise((re, rj) => {
      const data = {
        cellphone: this.editUserData.cellphone,
        send_type: "modify" as const,
        original_ip: DataStore.GetSessionStorage('clientIp')
      }
      this.userServiceApi.sentSms(user_id, data).then(res => {
        if (res.data.status) {
          re({
            status: true,
            msg: '请求成功',
            data: res
          })
        } else {
          if (res.data.code === '412001') {
            re({
              status: false,
              msg: '请输入正确的手机号',
            })
          } else if (res.data.code === '412002') {
            re({
              status: false,
              msg: '1分钟后再试',
            })
          }
          else if (res.data.code === '410002') {
            re({
              status: false,
              msg: '该号码已被注册',
            })
          }
          else {
            re({
              status: false,
              msg: '短信服务发生异常,请稍后再试',
            })
          }

        }
      }).catch(e => {
        re({
          status: false,
          msg: '请求失败',
        })
      })
    })
  }
  /** 发送邮箱验证码 */
  public sendEmailCaptcha(userId: string, email = ""): Promise<{ status: boolean; msg: string; data?: any; }> {
    return new Promise((re, rj) => {
      const data = {
        email: email ? email : this.editUserData.email,
      }
      this.userServiceApi.sentEmail(userId, data).then(res => {
        if (res.data.status) {
          re({
            status: true,
            msg: '发送成功！'
          })
        }
        else if (res.data.code == '410003') {
          re({
            status: false,
            msg: '发送失败！'
          })
        }
        else if (res.data.code == '410007') {
          re({
            status: false,
            msg: '验证码已发送，请稍后再试。'
          })
        }
      }
      )
    })
  }
  /** 发送身份验证码 */
  public sendCaptcha(userId: string, phone: string): Promise<{ status: boolean; msg: string; data?: any; }> {
    return new Promise((re, rj) => {
      const data = {
        cellphone: phone,
        send_type: 'verify' as const,
        original_ip: ''
      }
      this.userServiceApi.sentSms(userId, data).then(res => {
        if (res.data.status) {
          re({
            status: true,
            msg: '发送成功！'
          })
        }
        else {
          if (res.data.code === '412001') {
            re({
              status: false,
              msg: '请输入正确的手机号',
            })
          } else if (res.data.code === '412002') {
            re({
              status: false,
              msg: '1分钟后再试',
            })
          }
          else if (res.data.code === '410002') {
            re({
              status: false,
              msg: '该号码已被注册',
            })
          }
          else {
            re({
              status: false,
              msg: '短信服务发生异常,请稍后再试',
            })
          }

        }
      }).catch(e => {
        re({
          status: false,
          msg: '请求失败',
        })
      })
    })
  }
  /** 验证身份验证码 */
  public checkCaptcha(phone: string, captcha: string): Promise<{ status: boolean; msg: string; data?: any; }> {
    return new Promise((re, rj) => {
      const data = {
        cellphone: phone,
        captcha: captcha,
        verify_type: 'verify' as const,
      }
      this.userServiceApi.captchaVerify(data).then(res => {
        if (res.data.code == '000000') {
          re({
            status: true,
            msg: '验证成功！'
          })
        }
        else {
          re({
            status: false,
            msg: '验证失败！',
          })

        }
      }).catch(e => {
        re({
          status: false,
          msg: '验证失败！',
        })
      })
    })
  }
  /** 更改相应用户手机号 */
  public editUserCellphone(userId: string): Promise<{ status: boolean; msg: string; data?: any; }> {
    return new Promise((re, rj) => {
      const data = {
        user_id: '',
        account: '',
        user_name: '',
        user_icon: null,
        icon_name: '',
        new_password: '',
        old_password: '',
        cellphone: this.editUserData.cellphone,
        email: '',
        captcha: this.editUserData.captcha,
        theme_color: '',
        duration: null,
        workspace_authorized: null,
        store_authorized: null,
        xiaotie_authorized: null
      }
      this.userServiceApi.updateUser(userId, data).then(res => {
        if (res.data.status) {
          re({
            status: true,
            msg: '修改成功！'
          })
        } else if (res.data.code == '410003') {
          re({
            status: false,
            msg: '修改失败！'
          })
        } else {
          re({
            status: false,
            msg: '账号/手机号已经被注册'
          })
        }
        // else if (res.data.code == '410002') {
        //     re({
        //         status: false,
        //         msg: '账号/手机号已经被注册'
        //     })
        // }
      }
      )
    })
  }
  /** 解除绑定github token */
  deleteGiteaToken(userId: string): Promise<{ status: boolean; msg: string; data?: boolean; }> {
    return new Promise((re, rj) => {
      this.userServiceApi.deleteGitToken(userId, this.userGithubTokenId).then(res => {
        if (res.data.status) {
          re({
            status: true,
            msg: '修改成功！'
          })
        } else if (res.data.code == '910004') {
          re({
            status: false,
            msg: '修改失败！'
          })
        } else if (res.data.code == '999999') {
          re({
            status: false,
            msg: '修改失败！'
          })
        }
      }
      )
    })
  }
  //校验git-token 有效性
  validateToken(): Promise<{ status: boolean; msg: string; data?: any; }> {
    return new Promise((re, rj) => {
      this.cloudServiceApi.postGitValidateToken(this.codebase).then(res => {
        if (res.data.code == '000000') {
          re({
            status: true,
            data: res.data.res,
            msg: '审查成功'
          })
        } else {
          re({
            status: false,
            data: res.data.res,
            msg: '审查失败'
          })
        }
      })
    })
  }
  //绑定gitea-token
  onBindGiteaToken(userId: string): Promise<{ status: boolean; msg: string; data?: any; }> {
    return new Promise((re, rj) => {
      this.userServiceApi.postGitToken(userId, { type: "gitea", token: '' }).then(res => {
        if (res.data.status) {
          re({
            status: true,
            msg: '绑定成功！'
          })
        } else if (res.data.code == '410015') {
          re({
            status: false,
            msg: '邮箱未注册！'
          })
        } else if (res.data.code == '410016') {
          re({
            status: false,
            msg: 'Token已存在！'
          })
        } else if (res.data.code == '910001') {
          re({
            status: false,
            msg: '绑定失败！'
          })
        } else if (res.data.code == '999999') {
          re({
            status: false,
            msg: '绑定失败！'
          })
        }
      })
    })
  }
  //绑定github-token
  onBindGithubToken(userId: string): Promise<{ status: boolean; msg: string; data?: any; }> {
    return new Promise((re, rj) => {
      const data = {
        type: this.codebase.type == 'GitHub' ? 'github' as const : 'gitea' as const,
        token: this.codebase.token,
        organization: this.codebase.organization
      }
      this.userServiceApi.postGitToken(userId, data).then(res => {
        if (res.data.status) {
          re({
            status: true,
            msg: '绑定成功！'
          })
        } else if (res.data.code == '410015') {
          re({
            status: false,
            msg: '邮箱未注册！'
          })
        } else if (res.data.code == '410016') {
          re({
            status: false,
            msg: 'Token已存在！'
          })
        } else if (res.data.code == '910001') {
          re({
            status: false,
            msg: '绑定失败！'
          })
        } else if (res.data.code == '999999') {
          re({
            status: false,
            msg: '绑定失败！'
          })
        }
      })
    })
  }
}