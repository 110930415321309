import {
  registerMicroApps,
  runAfterFirstMounted,
  setDefaultMountApp,
  start,
} from "qiankun";
import qiankunAction from "./action";
import { DataStore } from "@/models/utils/dataStore";

/**
 * Step1 注册子应用
 */

export const microApps = [
  {
    name: "cloudAppPlatform",
    entry: process.env.VUE_APP_CLOUDAPP_ADDR,
    activeRule: "/cloudAppPlatform",
  },
  {
    name: "appMarket",
    entry: process.env.VUE_APP_APPMARKET_ADDR,
    activeRule: "/appMarket",
  },
  {
    name: "xiaotie",
    entry: process.env.VUE_APP_XIAOTIE_ADDR,
    activeRule: "/xiaotie",
  },
];

// 初始化globalState
let savedToken = DataStore.GetCookie("user-token");
if (savedToken) {
  qiankunAction.setGlobalState({ token: savedToken });
}

const apps = microApps.map((item) => {
  return {
    ...item,
    container: "#subapp-viewport", //  子应用挂载的 div
    props: {
      qiankunAction,
      msg: "消息",
    },
  };
});

// 子应用挂载的几个生命周期钩子
registerMicroApps(apps, {
  beforeLoad: (app) => {
    console.log("before load app.name====>>>>>", app.name);
  },
  beforeMount: [
    (app) => {
      console.log("[LifeCycle] before mount %c%s", "color: green;", app.name);
    },
  ],
  afterMount: [
    (app) => {
      console.log("[LifeCycle] after mount %c%s", "color: green;", app.name);
    },
  ],
  afterUnmount: [
    (app) => {
      console.log("11[LifeCycle] after unmount %c%s", "color: green;", app.name);
    },
  ],
});

/**
 * Step2  设置挂载的子应用
 */
setDefaultMountApp("/");

/**
 * Step3  启动应用
 */
export function qianKunStart() {
  start();
  // {
  //   sandbox: { experimentalStyleIsolation: true }
  // }{}
  // {
  //   prefetch:'all'
  // }
}

runAfterFirstMounted(() => {
  console.log("[MainApp] first app mounted");
});

// export default start;
