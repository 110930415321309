/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CaptchaVerifyData,
  DeleteGitTokenData,
  EnableAuthorizationData,
  GetAuthorizationData,
  GetGitTokenData,
  GetUserData,
  ListInviteCodesData,
  LoginData,
  LoginPayload,
  LogoutData,
  OneStoreLoginData,
  PostGitTokenData,
  PostGitTokenPayload,
  PostInviteCodeData,
  RegisterData,
  RegisterPayload,
  SentEmailData,
  SentEmailPayload,
  SentSmsData,
  SentSmsPayload,
  UpdateUserData,
  UpdateUserPayload,
  VerifyUserIdData,
  VerifyUserIdPayload,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class UserServiceApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags UserServiceAPI
   * @name Register
   * @summary 注册
   * @request POST:/signup/register
   */
  register = (
    query: {
      /**
       * 通过邮箱或者手机号注册时需要携带验证码
       * @example "123456"
       */
      captcha: string;
    },
    data: RegisterPayload,
    params: RequestParams = {},
  ) =>
    this.request<RegisterData, any>({
      path: `/signup/register`,
      method: "POST",
      query: query,
      body: data,
      ...params,
    });
  /**
   * No description
   *
   * @tags UserServiceAPI
   * @name VerifyUserId
   * @summary 注册时用户ID实时验证
   * @request POST:/signup/validate-id
   */
  verifyUserId = (data: VerifyUserIdPayload, params: RequestParams = {}) =>
    this.request<VerifyUserIdData, any>({
      path: `/signup/validate-id`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags UserServiceAPI
   * @name Login
   * @summary 用户登录
   * @request POST:/login
   */
  login = (data: LoginPayload, params: RequestParams = {}) =>
    this.request<LoginData, any>({
      path: `/login`,
      method: "POST",
      body: data,
      ...params,
    });
  /**
   * No description
   *
   * @tags UserServiceAPI
   * @name Logout
   * @summary 用户退出登录
   * @request POST:/logout
   */
  logout = (params: RequestParams = {}) =>
    this.request<LogoutData, any>({
      path: `/logout`,
      method: "POST",
      ...params,
    });
  /**
   * No description
   *
   * @tags UserServiceAPI
   * @name OneStoreLogin
   * @summary OneStore用户登录
   * @request POST:/onestore-login
   */
  oneStoreLogin = (
    query?: {
      /** 授权码 */
      authorization_code?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<OneStoreLoginData, any>({
      path: `/onestore-login`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags UserServiceAPI
   * @name GetUser
   * @summary 获取用户信息
   * @request GET:/users/{user_id}
   */
  getUser = (
    userId: string,
    query: {
      /**
       * 用户信息获取方式（"0"：根据传入的用户ID获取 "1": 根据当前发起请求用户的token获取用户信息）
       * @example "0"
       */
      get_type: string;
      /** 全局搜索用户的时候传入的关键字（可以是用户名，邮箱，手机号） */
      keyword?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetUserData, any>({
      path: `/users/${userId}`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags UserServiceAPI
   * @name UpdateUser
   * @summary 修改用户信息
   * @request PUT:/users/{user_id}
   */
  updateUser = (userId: string, data: UpdateUserPayload, params: RequestParams = {}) =>
    this.request<UpdateUserData, any>({
      path: `/users/${userId}`,
      method: "PUT",
      body: data,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags UserServiceAPI
   * @name SentEmail
   * @summary 邮箱发送6位数字验证码
   * @request POST:/users/{user_id}/send-email
   */
  sentEmail = (userId: string, data: SentEmailPayload, params: RequestParams = {}) =>
    this.request<SentEmailData, any>({
      path: `/users/${userId}/send-email`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags UserServiceAPI
   * @name SentSms
   * @summary 发送6位数字验证码短信
   * @request POST:/users/{user_id}/send-sms
   */
  sentSms = (userId: string, data: SentSmsPayload, params: RequestParams = {}) =>
    this.request<SentSmsData, any>({
      path: `/users/${userId}/send-sms`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags UserServiceAPI
   * @name CaptchaVerify
   * @summary 手机验证码验证
   * @request GET:/captcha/verify
   */
  captchaVerify = (
    query: {
      /** 手机号 */
      cellphone: string;
      /** 验证码 */
      captcha: string;
      /** 验证类型，目前有 login,register,modify,verify */
      verify_type: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<CaptchaVerifyData, any>({
      path: `/captcha/verify`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags UserServiceAPI
   * @name GetGitToken
   * @summary 获取用户的git-token
   * @request GET:/users/{user_id}/git-token
   */
  getGitToken = (
    userId: string,
    query?: {
      /**
       * 获取用户各个类型的token（"gitea"：获取用户的gitea类型token "github": 获取用户的github类型token 不传则表示获取所有类型的token）
       * @example "gitea"
       */
      type?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetGitTokenData, any>({
      path: `/users/${userId}/git-token`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * @description 绑定git token
   *
   * @tags UserServiceAPI
   * @name PostGitToken
   * @summary 绑定git token
   * @request POST:/users/{user_id}/git-token
   */
  postGitToken = (userId: string, data: PostGitTokenPayload, params: RequestParams = {}) =>
    this.request<PostGitTokenData, any>({
      path: `/users/${userId}/git-token`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 解除绑定git token
   *
   * @tags UserServiceAPI
   * @name DeleteGitToken
   * @summary 解除绑定git token
   * @request DELETE:/users/{user_id}/git-token/{token_id}
   */
  deleteGitToken = (userId: string, tokenId: string, params: RequestParams = {}) =>
    this.request<DeleteGitTokenData, any>({
      path: `/users/${userId}/git-token/${tokenId}`,
      method: "DELETE",
      ...params,
    });
  /**
   * @description 根据user_id获取用户在某个平台的授权状态（云工作空间/应用商店/小铁）
   *
   * @tags UserServiceAPI
   * @name GetAuthorization
   * @summary 根据user_id获取用户在某个平台的授权状态（云工作空间/应用商店/小铁）
   * @request GET:/workspace/users/{user_id}/authorization/{platform}
   */
  getAuthorization = (userId: string, platform: "workspace" | "store" | "xiaotie", params: RequestParams = {}) =>
    this.request<GetAuthorizationData, any>({
      path: `/workspace/users/${userId}/authorization/${platform}`,
      method: "GET",
      ...params,
    });
  /**
   * @description 根据user_id给予用户在某个平台的授权状态（云工作空间/应用商店/小铁）
   *
   * @tags UserServiceAPI
   * @name EnableAuthorization
   * @summary 根据user_id给与用户在某个平台的授权状态（云工作空间/应用商店/小铁）
   * @request POST:/workspace/users/{user_id}/authorization/{platform}
   */
  enableAuthorization = (userId: string, platform: "workspace" | "store" | "xiaotie", params: RequestParams = {}) =>
    this.request<EnableAuthorizationData, any>({
      path: `/workspace/users/${userId}/authorization/${platform}`,
      method: "POST",
      ...params,
    });
  /**
   * @description 获取邀请码列表
   *
   * @tags UserServiceAPI
   * @name ListInviteCodes
   * @summary 获取邀请码列表
   * @request GET:/workspace/invite-codes
   */
  listInviteCodes = (params: RequestParams = {}) =>
    this.request<ListInviteCodesData, any>({
      path: `/workspace/invite-codes`,
      method: "GET",
      ...params,
    });
  /**
   * @description 创建邀请码
   *
   * @tags UserServiceAPI
   * @name PostInviteCode
   * @summary 创建邀请码
   * @request POST:/workspace/invite-codes
   */
  postInviteCode = (params: RequestParams = {}) =>
    this.request<PostInviteCodeData, any>({
      path: `/workspace/invite-codes`,
      method: "POST",
      ...params,
    });
}
