import * as moment from "moment";
class DataTransfer {
    static Moment: moment.Moment;

    /**
     * @description 比较两个对象，若键值一样则将resourceObj的值赋给resultObj
     * @param {resourceObj} 来源对象
     * @param {resultObj} 输出对象
    */
    static MappingObject(resourceObj: Record<string, any>, resultObj: Record<string, any>) {
        Object.keys(resultObj).forEach(key =>{
            if(resourceObj[key] == undefined) {
                // resultObj[key] = resultObj[key]
            }
            else if(resourceObj[key].type == Array){
                resultObj[key].splice(0,resultObj[key].length)
                for(let i=0; i<resourceObj[key].length;i++){
                    resultObj[key].push(resourceObj[key][i])
                }
            }
            else if(resourceObj[key].type !== Object){
                resultObj[key] = resourceObj[key]
            }
            else {
                this.MappingObject(resourceObj[key],resultObj[key])
            }
        })
    }

}

export { DataTransfer };