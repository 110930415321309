import { IUserDeletor } from "../../../interfaces/userService";
import { UserServiceApi } from "@/models/apis/UserServiceApi";

export class UserDeletor implements IUserDeletor {
    constructor(api: UserServiceApi) {
        this.userServiceApi = api
    }
    private userServiceApi: UserServiceApi;
    deleteUser(userId: string): Promise<{ status: boolean; msg: string; data?: any; }> {
        throw new Error("Method not implemented.");
    }
}