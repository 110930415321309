/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateNfsFileData,
  CreateNfsFilePayload,
  DeleteIdeData,
  DeleteNfsFileData,
  DeleteNfsFilePayload,
  DownloadNfsFileData,
  GetIdeData,
  GetNfsFileChunkCheckData,
  GetNfsFileListData,
  IDEInstance,
  PostGitValidateTokenData,
  PostGitValidateTokenPayload,
  PostIdeData,
  PostMergeNfsFileChunkData,
  PostMergeNfsFileChunkPayload,
  PostToolsApiTestData,
  PostToolsApiTestPayload,
  PostUploadNfsChunkData,
  PostUploadNfsChunkPayload,
  PostUploadNfsFileData,
  PostUploadNfsFilePayload,
  RenameNfsFileData,
  RenameNfsFilePayload,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class CloudServiceApi<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description 部署IDE服务
   *
   * @tags CloudServiceAPI
   * @name PostIde
   * @summary 部署IDE服务
   * @request POST:/cloud-services/ide
   */
  postIde = (data: IDEInstance, params: RequestParams = {}) =>
    this.request<PostIdeData, any>({
      path: `/cloud-services/ide`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 删除IDE服务
   *
   * @tags CloudServiceAPI
   * @name DeleteIde
   * @summary 删除IDE服务
   * @request DELETE:/cloud-services/ide/{app_id}
   */
  deleteIde = (appId: string, params: RequestParams = {}) =>
    this.request<DeleteIdeData, any>({
      path: `/cloud-services/ide/${appId}`,
      method: "DELETE",
      ...params,
    });
  /**
   * @description 检查IDE服务是否存在
   *
   * @tags CloudServiceAPI
   * @name GetIde
   * @summary 检查IDE服务是否存在
   * @request GET:/cloud-services/ide/{app_id}
   */
  getIde = (appId: string, params: RequestParams = {}) =>
    this.request<GetIdeData, any>({
      path: `/cloud-services/ide/${appId}`,
      method: "GET",
      ...params,
    });
  /**
   * @description 校验git token的有效性
   *
   * @tags CloudServiceAPI
   * @name PostGitValidateToken
   * @summary 校验git token的有效性
   * @request POST:/cloud-services/git/validate-token
   */
  postGitValidateToken = (
    data: PostGitValidateTokenPayload,
    params: RequestParams = {}
  ) =>
    this.request<PostGitValidateTokenData, any>({
      path: `/cloud-services/git/validate-token`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 用于云工作空间组件部署后的简单在线测试
   *
   * @tags CloudServiceAPI
   * @name PostToolsApiTest
   * @summary API调用测试
   * @request POST:/cloud-services/tools/api-test
   */
  postToolsApiTest = (
    data: PostToolsApiTestPayload,
    params: RequestParams = {}
  ) =>
    this.request<PostToolsApiTestData, any>({
      path: `/cloud-services/tools/api-test`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 获取nfs服务指定目录文件列表
   *
   * @tags CloudServiceAPI
   * @name GetNfsFileList
   * @summary 获取nfs实例指定目录文件列表
   * @request GET:/cloud-services/nfs/{instance_id}/files
   */
  getNfsFileList = (
    instanceId: string,
    query: {
      /** 文件目录路径，为空时默认获取根目录文件列表 */
      path: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<GetNfsFileListData, any>({
      path: `/cloud-services/nfs/${instanceId}/files`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * @description 在NFS服务指定目录下新建文件/文件夹
   *
   * @tags CloudServiceAPI
   * @name CreateNfsFile
   * @summary 新建文件/文件夹
   * @request POST:/cloud-services/nfs/{instance_id}/files
   */
  createNfsFile = (
    instanceId: string,
    data: CreateNfsFilePayload,
    params: RequestParams = {}
  ) =>
    this.request<CreateNfsFileData, any>({
      path: `/cloud-services/nfs/${instanceId}/files`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 重命名或移动/复制NFS服务中的文件/文件夹
   *
   * @tags CloudServiceAPI
   * @name RenameNfsFile
   * @summary 重命名或移动/复制文件/文件夹
   * @request PUT:/cloud-services/nfs/{instance_id}/files
   */
  renameNfsFile = (
    instanceId: string,
    data: RenameNfsFilePayload,
    params: RequestParams = {}
  ) =>
    this.request<RenameNfsFileData, any>({
      path: `/cloud-services/nfs/${instanceId}/files`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 删除nfs服务中的文件/文件夹
   *
   * @tags CloudServiceAPI
   * @name DeleteNfsFile
   * @summary 删除文件/文件夹
   * @request POST:/cloud-services/nfs/{instance_id}/files/delete
   */
  deleteNfsFile = (
    instanceId: string,
    data: DeleteNfsFilePayload,
    params: RequestParams = {}
  ) =>
    this.request<DeleteNfsFileData, any>({
      path: `/cloud-services/nfs/${instanceId}/files/delete`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description 上传文件到nfs实例
   *
   * @tags CloudServiceAPI
   * @name PostUploadNfsFile
   * @summary 上传文件到nfs实例
   * @request POST:/cloud-services/nfs/{instance_id}/files/upload
   */
  postUploadNfsFile = (
    instanceId: string,
    data: PostUploadNfsFilePayload,
    params: RequestParams = {}
  ) =>
    this.request<PostUploadNfsFileData, any>({
      path: `/cloud-services/nfs/${instanceId}/files/upload`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * @description 从nfs实例下载文件（获取文件内容）或文件夹（压缩包）
   *
   * @tags CloudServiceAPI
   * @name DownloadNfsFile
   * @summary 从nfs实例下载文件（获取文件内容）或文件夹（压缩包）
   * @request GET:/cloud-services/nfs/{instance_id}/files/download
   */
  downloadNfsFile = (
    instanceId: string,
    query: {
      /** 文件路径 */
      path: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<DownloadNfsFileData, any>({
      path: `/cloud-services/nfs/${instanceId}/files/download`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * @description 文件分片上传检查
   *
   * @tags CloudServiceAPI
   * @name GetNfsFileChunkCheck
   * @summary 文件分片上传检查
   * @request GET:/cloud-services/nfs/{instance_id}/files/chunk/check
   */
  getNfsFileChunkCheck = (
    instanceId: string,
    query: {
      /** 对文件使用MD5算法生成的散列值 */
      hash: string;
      /**
       * 文件大小
       * @format int64
       */
      size: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<GetNfsFileChunkCheckData, any>({
      path: `/cloud-services/nfs/${instanceId}/files/chunk/check`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * @description 上传文件分片到nfs实例
   *
   * @tags CloudServiceAPI
   * @name PostUploadNfsChunk
   * @summary 上传文件分片到nfs实例
   * @request POST:/cloud-services/nfs/{instance_id}/files/chunk/upload
   */
  postUploadNfsChunk = (
    instanceId: string,
    data: PostUploadNfsChunkPayload,
    params: RequestParams = {}
  ) =>
    this.request<PostUploadNfsChunkData, any>({
      path: `/cloud-services/nfs/${instanceId}/files/chunk/upload`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * @description 合并文件分片
   *
   * @tags CloudServiceAPI
   * @name PostMergeNfsFileChunk
   * @summary 合并文件分片
   * @request POST:/cloud-services/nfs/{instance_id}/files/chunk/merge
   */
  postMergeNfsFileChunk = (
    instanceId: string,
    data: PostMergeNfsFileChunkPayload,
    params: RequestParams = {}
  ) =>
    this.request<PostMergeNfsFileChunkData, any>({
      path: `/cloud-services/nfs/${instanceId}/files/chunk/merge`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      ...params,
    });
}
